<template>
<h1>De {{ resource }} die u zoekt, is niet langer beschikbaar!</h1>
<router-link :to="{name:'Home'}">HOME</router-link>
</template>
<script>
export default
{
props:{
  resource:{
type: String,
require: true,
default: 'pagina'}
}
}

</script>